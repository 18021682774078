/*
    This file is used to store often-changed configs used by codeowned parts of RSDK.
    You are free to make changes in here, just be careful of course!
*/

/* Used in modules/Network/axios/getStatsdTagForUrl to prevent unbounded url tags */
export const STATSD_URL_TAG_MAP_ARRAY: Array<{
  regex: RegExp;
  tag: string;
}> = [
  // MORE SPECIFIC REGEXES MUST GO FIRST, FIRST MATCH WINS
  {
    regex: /https:\/\/api\.lever\.co\/v0\/postings\/rover\?group=team&mode=json/,
    tag: 'https://api.lever.co/v0/postings/rover?group=team&mode=json',
  },
  {
    regex: /\/frontend\/homepage-rebooking-profiles\//,
    tag: '/frontend/homepage-rebooking-profiles/',
  },
  {
    regex: /\/frontend\/current-user\//,
    tag: '/frontend/current-user/',
  },
  {
    regex: /\/frontend\/experiments\//,
    tag: '/frontend/experiments/',
  },
  {
    regex: /\/search-optimized\//,
    tag: '/search-optimized/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/home\//,
    tag: '/people/:personOpk/home/',
  },
  {
    regex: /\/goodpup-reviews\//,
    tag: '/goodpup-reviews/',
  },
  {
    regex: /\/cities-and-neighborhoods\//,
    tag: '/cities-and-neighborhoods/',
  },
  {
    regex: /\/country-configuration\/current\//,
    tag: '/country-configuration/current/',
  },
  {
    regex: /\/country-configuration\/navigation\//,
    tag: '/country-configuration/navigation/',
  },
  {
    regex: /\/country-configuration\//,
    tag: '/country-configuration/',
  },
  {
    regex: /\/frontend\/country-footer-links\//,
    tag: '/country-footer-links/',
  },
  {
    regex: /\/frontend\/country-region-links\//,
    tag: '/frontend/country-region-links/',
  },
  {
    regex: /\/frontend\/events\//,
    tag: '/frontend/events/',
  },
  {
    regex: /\/frontend\/configuration\//,
    tag: '/frontend/configuration/',
  },
  {
    regex: /\/announcements\/global\//,
    tag: '/announcements/global/',
  },
  {
    regex: /\/frontend\/href-langs\//,
    tag: '/frontend/href-langs/',
  },
  {
    regex: /\/utilities\/location-autocomplete\//,
    tag: '/utilities/location-autocomplete/',
  },
  {
    regex: /\/utilities\/location-geocode\//,
    tag: '/utilities/location-geocode/',
  },
  {
    regex: /\/announcements\/notifications\//,
    tag: '/announcements/notifications/',
  },
  {
    regex: /\/payment-intents\//,
    tag: '/payment-intents/',
  },
  {
    regex: /\/frontend\/user-person-summary\//,
    tag: '/frontend/user-person-summary/',
  },
  {
    regex: /\/phones\//,
    tag: '/phones/',
  },
  {
    regex: /\/pets\//,
    tag: '/pets/',
  },
  {
    regex: /\/my-pets\//,
    tag: '/my-pets/',
  },
  {
    regex: /\/seo-location\//,
    tag: '/seo-location/',
  },
  {
    regex: /\/reviews-snippet\//,
    tag: '/reviews-snippet/:city/:region/:serviceType',
  },
  {
    regex: /\/frontend\/service-types\//,
    tag: '/frontend/service-types/',
  },
  {
    regex: /\/frontend\/top-cities\//,
    tag: '/frontend/top-cities/',
  },
  {
    regex: /\/frontend\/footer\//,
    tag: '/frontend/footer/',
  },
  {
    regex: /\/frontend\/homepage-configuration\//,
    tag: '/frontend/homepage-configuration/',
  },
  {
    regex: /\/frontend\/homepage-service-types\//,
    tag: '/frontend/homepage-service-types/',
  },
  {
    regex: /\/frontend\/service-links\//,
    tag: '/frontend/service-links/',
  },
  {
    regex: /\/frontend\/internal-linking\//,
    tag: '/frontend/internal-linking/',
  },
  {
    regex: /\/people\/full-sitter-profile\//,
    tag: '/people/full-sitter-profile/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/public-calendar\//,
    tag: '/people/:opk/public-calendar/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/reviews\//,
    tag: '/people/:opk/reviews/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/stay-media\//,
    tag: '/people/:opk/stay-media/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/images\//,
    tag: '/people/:opk/images/',
  },
  {
    regex: /\/provider-profile\/hub\//,
    tag: '/provider-profile/hub/',
  },
  {
    regex: /\/provider-profile\/cat-profile\//,
    tag: '/provider-profile/cat-profile/',
  },
  {
    regex: /\/provider-profile\/trainer-sitter-sign-up\/[a-zA-Z0-9-]+\/cat-profile\//,
    tag: '/provider-profile/trainer-sitter-sing-up/:serviceSlug/cat-profile/',
  },
  {
    regex: /\/frontend\/service-landing\//,
    tag: '/frontend/service-landing/',
  },
  {
    regex: /\/facilities\/[a-zA-Z0-9]+\/contact\//,
    tag: '/facilities/:opk/contact/',
  },
  {
    regex: /\/facilities\/[a-zA-Z0-9]+\//,
    tag: '/facilities/:opk/',
  },
  {
    regex: /\/facility-booking\/[a-zA-Z0-9]+\//,
    tag: '/facility-booking/:opk/',
  },
  {
    regex: /\/reviews\/facility\/[a-zA-Z0-9]+\//,
    tag: '/reviews/facility/:opk/',
  },
  {
    regex: /\/reviews\/trainer-testimonial-requests\/[a-zA-Z0-9]+\//,
    tag: '/reviews/trainer-testimonial-requests/:token/',
  },
  {
    regex: /\/reviews\/trainer-testimonial-requests\//,
    tag: '/reviews/trainer-testimonial-requests/',
  },
  {
    regex: /\/training\/members\/[a-zA-Z-]+\/services\/[a-zA-Z-]+\/bundle-offerings\//,
    tag: '/training/members/:providerSlug/services/:serviceSlug/bundle-offerings/',
  },
  {
    regex: /\/training\/members\/[^/]+\//,
    tag: '/training/members/:slug/',
  },
  {
    regex: /\/training\/trainer-testimonials\/add\//,
    tag: '/training/trainer-testimonials/add/',
  },
  {
    regex: /\/training\/methodology-requirement\//,
    tag: '/training/methodology-requirement/',
  },
  {
    regex: /\/training\/certification-requirement\//,
    tag: '/training/certification-requirement/',
  },
  {
    regex: /\/training\/trainer-profile\/behaviors\//,
    tag: '/training/trainer-profile/behaviors/',
  },
  {
    regex: /\/training\/trainer-profile\/skills\//,
    tag: '/training/trainer-profile/skills/',
  },
  {
    regex: /\/training\/trainer-profile\/behaviors-custom\//,
    tag: '/training/trainer-profile/behaviors-custom/',
  },
  {
    regex: /\/training\/trainer-profile\/skills-custom\//,
    tag: '/training/trainer-profile/skills-custom/',
  },
  {
    regex: /\/training\/trainer-profile\/details\//,
    tag: '/training/trainer-profile/details/',
  },
  {
    regex: /\/training\/search-results\//,
    tag: '/training/search-results/',
  },
  {
    regex: /\/training\/trainer-profile\/education\//,
    tag: '/training/trainer-profile/education/',
  },
  {
    regex: /\/provider-profile\/person\/[a-zA-Z-]+\//,
    tag: '/provider-profile/person/:service_preferences_slug/',
  },
  {
    regex: /\/conversations\/[a-zA-Z0-9]+\//,
    tag: '/conversations/:conversation_opk/',
  },
  {
    regex: /\/people\/[a-zA-Z0-9]+\/services\/[^/]+\//,
    tag: '/people/:opk/services/:service_slug/',
  },
  {
    regex: /\/training\/session-cancellation-reasons\/(provider|owner)\//,
    tag: '/training/session-cancellation-reasons/:user_type/',
  },
  {
    regex: /\/order\/[a-zA-Z0-9]+/,
    tag: '/order/:opk/',
  },
  {
    regex: /\/checkout\/[a-zA-Z0-9]+\/confirmation-details/,
    tag: '/checkout/:opk/confirmation-details/',
  },
];

/* Used in modules/Data/Core/MobileFeatureFlag.native
     native flags are checked by passing these lowercase snakecase strings across the isFeatureFlagActive bridge
  */
export const NATIVE_APP_FLAGS = [
  'killswitch_native_screen_metric',
  'rollout_insights_dashboard_star_sitter_callout',
  'rollout_rxn_respond_to_review',
  'rollout_sitter_leaves_feedback_m5_pet_feedback',
  'rollout_behavioural_signals',
  'rollout_behavioural_signals_experiment',
  'rollout_sitter_leaves_review_m5',
  'rollout_edit_pet_insurance_field',
  'rollout_insights_dashboard_star_sitter_metrics',
  'rollout_negative_pet_feedback_iteration',
  'rollout_known_pets_sitter_feedback',
  'rollout_star_sitter_rolling_cutover',
  'rollout_ios_native_help_center',
  'rollout_android_native_help_center',
  'rollout_report_and_block_subreasons',
  'rollout_provider_embedded_request_editor',
  'rollout_sitter_promotion_m0_entry_points',
  'rollout_home_profile_v2',
] as const;

/** Used in modules/Data/Core/FrontendConfiguration
 * human-readable names for the hashes on the frontendConfiguration.flags API response
 */
export const FRONTEND_CONFIGURATION_FLAGS = {
  CAT_REVIEWS_IN_SEARCH_EXPERIMENT: '0dbadb3de3c4c09e01f86be00d8a409e9c7cd438',
  DEVICE_CURRENT_LOCATION_EXPERIMENT: '0367fc4fe5952478f3bd3c719a6c7e9ee8a079cd',
  KILLSWITCH_PET_INSURANCE_INTEGRATION: '5cf50e42f867f21211db5cd7c2e9f24025892fc1',
  KILLSWITCH_SSR_CACHE_TTL: '9224cd53b2dbf66aa298b5da404da7e9cebdce6e',
  QUICK_FACTS_EXPERIMENT: '6084a8f35600ecf3a7c8ecbba3e9cf061fcebc0a',
  REPEAT_SEARCH_EXPERIMENT_20180620_VARIANT: '6b74ac8a0fb97f8f8e50321354750a4afabb4e8a',
  ROLLOUT_AUTO_SET_ADDITIONAL_RATES: '55029b9a2283c9537c48e9709329629f61daceda',
  ROLLOUT_CLOUDFLARE_SEARCH_REPLICATE_EVENT: 'b3ef9efbf288ab3f157a34374068cbff269f345d',
  ROLLOUT_EDIT_PET_INSURANCE_FIELD: '27e526f3d12cc45f619ebc01c66697fc96046f5a',
  ROLLOUT_INPUT_DEPENDENT_PERFORMANCE: 'e6f88f0aef4bba4a153f072a86bfa039e2b5659d',
  ROLLOUT_INTERNAL_LINKING: '045b9979887329d4a8981b39b5f50475c16f0dbc',
  ROLLOUT_KETCH_COOKIE_CONSENT: 'a45c39ca9259ae0c55d0a159a502938aef891fba',
  ROLLOUT_SIGN_STATIC_MAP: 'da635c28fc4ff68e452c58a1b0607f3e618884f5',
  ROLLOUT_SITTER_INSIGHTS_ALL_CHARTS: '8a1259985afbbcfb28ca301664779a61a82ea43f',
  ROLLOUT_SITTER_INSIGHTS_PROFILE_VIEWS_CHART: 'b5f0574d0f254849a37a1be5400cbec896c46ea4',
  ROLLOUT_SSR_HOMEPAGE_FULLSTORY: '9ee23cbb87e44c9a299e9fbba6af3792b295e093',
  ROLLOUT_SSR_SEARCH_SESSION_REPLAY: 'a4da5bafaf9e1573b4f37f775ace748359c16a3c',
  ROLLOUT_SSR_SEARCH_FULLSTORY: 'e9a92cd1257d9458e48b0ccafb3bc81b08ee760c',
  ROLLOUT_TRUSTPILOT_BADGE_SCRIPT: '88e2595d30a2fc18be1c79637587d6e5eeb9a5ca',
  ROLLOUT_TRUSTPILOT_BADGE_STATIC_IMAGE: 'b61bfb4a88c8070cd6e059365d4bb8c44701827d',
  ROLLOUT1_SIMPLIFIED_MOBILE_OWNER_SEARCH_FORM: '2814f3822b77980fdf9f5204d8293aaba91f579c',
  ROLLOUT2_SIMPLIFIED_MOBILE_OWNER_SEARCH_FORM: '94b0fadfb590f79dbc6acfcd5fbe9d43eeea9f3d',
  ROLLOUT3_SIMPLIFIED_MOBILE_OWNER_SEARCH_FORM: 'cb95342fb13803a9ccd526ea6bed43f47be36b10',
  ROLLOUT_EMBEDDED_WEB_GOODPUP_LINK: 'd6b95033ecd22de482a5d973fdcb0271cf06d7a1',
  ROLLOUT_CONTACT_PAGE_24H_PICKER: '35d9a21adf184c2d126d11e4da40466ca18b99f0',
  ROLLOUT_SITTER_LEAVES_REVIEW_M5: '7a8ff857c9a9ff310e25d0b569620204eba749d4',
  ROLLOUT_SSR_SITTER_PROFILE_FULLSTORY: 'a8c66b5d47f8c258327bae5d8eeb228edfe1a2f5',
  FEATURE_REDIRECT_USERS_TO_CORRECT_COUNTRY_PAGES: '0f9538762c24bc0a1b3b15cf06ecf01ec8ca4678',
  KILLSWITCH_INTERNAL_LINKING_NOINDEX: '7459f92db123655488944018a771a306f80e00cd',
  ROLLOUT_ROVER_PROTECT_CH_2: '3d5a3b311df372cdc9871e8b93ffadde88192e3e',
  KILLSWITCH_ROVER_PROTECT_CH_2_SURVEY: 'f922229a9ca23e9836709427b5fc106c3dae7f16',
  ROLLOUT_DOG_TRAINING_PILOT: 'cd1faa4fe50e12bec2b16cfae00a845f8fd2bdb9',
  ROLLOUT_DOG_TRAINING_PILOT_GLOBAL: '2d62e3c7be03a8d34d120a1a682882501d03296a',
  ROLLOUT_SSR_CITY_SERVICE_FULLSTORY: '390b5b2ce6b59713b4c435bb4a8df29df65aec6b',
  ROLLOUT_SEARCH_NOINDEX: '3e9e62de35bdcdeed38510d89169d20c335967d9',
  ROLLOUT_AMPLITUDE_ANALYTICS_WEB: 'a3424f5bc57ccdcfe677d32a70d157981d82ab4f',
  ROLLOUT_PRICE_TRANSPARENCY: '4cd2cda364d2b3f0a836670000945a58e6e8b849',
  ROLLOUT_INSIGHTS_METRICS_ON_PROVIDER_PROFILE: '9e881b7fea39e99192f0f35ee92dd070aa0c23ab',
  ROLLOUT_DIGITAL_PLATFORM_TAX_AGREEMENT: '36c17850ebaeeb9acfb1be33facc7700b7026c96',
  ROLLOUT_ZERO_UNIT_CONVERSATIONS: '83c4a3cb960065055bc975295425583c1222f99b',
  ROLLOUT_SSR_NAVBAR_COLLAPSIBLE_MENU: '4ec5c83137fb46b1398cf9926622e87adaea7793',
  ROLLOUT_SSR_FACILITY_PROFILE_FULLSTORY: '328af2fa44f37b3812db9198e22a7c19c783ea69',
  ROLLOUT_SSR_FACILITY_CONTACT_PAGE_FULLSTORY: '8278f05b7f99978e33897777738a82be6800d169',
  ROLLOUT_SSR_FACILITY_BOOKING_SUMMARY_FULLSTORY: '21a6d863759ce9265a43bfa189423d0e26258268',
  ROLLOUT_RXN_FACILITY_CONTACT_PAGE: 'f102ed80455f42b409ea8deeac904908c543c8a8',
  ROLLOUT_TRAINER_SIGN_UP: 'd062d043aef0b5e808a4711a6fa9983e167e388d',
  ROLLOUT_TRAINER_SIGN_UP_GLOBAL: 'a81f16f9fe7e800f77cb0d1fd3a2c122d4e7ef98',
  ROLLOUT_SSR_TRAINING_SEARCH_FULLSTORY: '1571fcdd3b02e9a23121467ff833c7906a83deeb',
  ROLLOUT_SSR_TRAINING_PUBLIC_TRAINER_PROFILE_FULLSTORY: '1e3211b0f4c942c0cf8213d71ee88f32d32a1803',
  ROLLOUT_SSR_TRAINING_CANCEL_BOOKING_FULLSTORY: 'fff7417cd484f7b49ceb5204f2e6aedc56e119ca',
  ROLLOUT_RECURRING_SERVICE_LISTING_PREFERENCES_UPDATES: '363400365415034fb46c88afd04708e6dc527424',
  ROLLOUT_CALIFORNIA_PRICE_TRANSPARENCY: 'a7be383f4db72590aab8f894c0986d24e69e7254',
  ROLLOUT_DOG_TRAINING_ENTRYPOINTS: '7e5fe227c7c4a45be9727143229a8eee75ad73e8',
  ROLLOUT_STAR_SITTER_ROLLING_CUTOVER: '1849eae9493a989be24e70a05734968dbc7dd8ca',
  ROLLOUT_TRAINING_SUPPRESS_APP_INSTALL_BANNER: '499a01735b53b2a666e350e70743c9270f3883ae',
  ROLLOUT_TRAINING_AVAILABILITY_CHANGES: '43e10ab61223be9b475d9316d5a36827749cd985',
  ROLLOUT_FACILITY_GUEST_LOGIN: 'eb35de36b0d30a9bc6fcded05b0ce50b860eae8e',
  ROLLOUT_IOS_FACILITY_FULLSTORY_EVENTS: '9473afffff70d2490e193cd17bc430641422a3f6',
  ROLLOUT_TRAINING_CONSULTATION_ADD_ONS: '907c53bd0c845ede15d31a65e8913b12430f5283',
  ROLLOUT_TRAINING_PAID_CONSULTATIONS: '599969e44ecc3b9ddfe2a62ef31331f15e948288',
  KILLSWITCH_ALLOW_PET_CREATION_VIA_NEW_PET_FORM: 'aef203a24520095d0a8152af14d203d7e5325c4e',
  ROLLOUT_FACILITY_VACCINE_UPLOAD_IMAGE: '64ba3deefa170f4aa8f5ce9cdd33caefe776a781',
  ROLLOUT_UNAUTHENTICATED_FACILITY_CONTACT_PAGE: 'fbcde0fa714fb60834abd73314cb8fad063a270b',
  ROLLOUT_TRAINER_PROFILE_MIXED_SERVICE_REVIEWS: 'cd79145143bcdd8935370633281d5786dda1f1ea',
  ROLLOUT_REVISED_TRAINER_SEARCH_CARDS: 'dedeb531837d26f328cdd98ae47caa9b472d6609',
  ROLLOUT_SITTER_PROMOTION_M0_OWNER: 'd2f4dbcc59e56e9bea608f5581f6554809e33b03',
  ROLLOUT_SITTER_PROMOTION_M0_LANDING_PAGE: '810eb3c9739a406f584107e6f9a28d1b6c41732a',
  ROLLOUT_TRAINING_FREE_CONSULTATIONS: '4aadcf345f5d077afe2e9d8132cda1d44d903c3b',
  ROLLOUT_PROVIDER_BUNDLE_OFFERINGS: 'b3dded3660ba9e955b6a5a7624cecca034761e6a',
  ROLLOUT_UPDATED_TRAINING_NEEDS: 'b8b54c0faef99744533329458ffa8937862a2712',
  ROLLOUT_TRAINING_SEARCH_NEEDS_FILTERS: '76a201180ba27b5fc6f8ee6f1f05eef2257ccb1a',
  ROLLOUT_SITTER_PROMOTION_M0_ENTRY_POINTS: 'd74057c86b7fe6a79e65ea4b38e1a962a65187fc',
  ROLLOUT_TRAINING_BUNDLES: '3cbc9ffa121c95d5def8362761baa1c781b6ae02',
  KILLSWITCH_FACILITY_BOOKING_ADDITIONAL_CONTACT_INFO: '28cbd88cc066c5e957be3b69c71b9ca2f2528910',
  KILLSWITCH_TRAINING_BUNDLE_CREATE_OFFERINGS: '434e52691e2a2b6da392f9533b552aa4976e1a26',
  KILLSWITCH_TRAINING_BUNDLE_VIEW_OFFERINGS: '6f77fed64e9a8d5d4753b201fc2a4f000c02064e',
  ROLLOUT_ROVER_COLLECT_VACCINATION_DOCUMENTS: '2c8a0804131ea041a2c5b4cf0ae9b4f8ba30687f',
  ROLLOUT_TRAINING_CREDENTIALS_UPLOAD: 'f610c003bf8bcb8ba8cd9d18876d9510cb6189c9',
} as const;

/* Used in modules/Data/Core/MobileExperiment.native
     native experiments are checked by passing these values across getExperimentBucket bridge
  */
export const NATIVE_EXPERIMENTS = {
  PAYPAL_PAYINS_ANDROID: 'Paypal Payins on Android',
  PAYPAL_PAYINS_IOS: 'Paypal Payins on iOS',
} as const;
